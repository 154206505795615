import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import Profile from "./pages/user/Profile";
import SignIn from "./pages/user/SignIn";
import SignUp from "./pages/user/SignUp";

import Landing from "./pages/landing/Landing";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import NotFound from "./pages/404";
import Cookies from "./pages/policies/Cookies";
import Privacy from "./pages/policies/Privacy";
import TermsAndConditions from "./pages/policies/TermsAndConditions";
import { ToastContainer } from "react-toastify";

import "antd/lib/select/style/css";
import "antd/lib/space/style/css";
import "antd/lib/form/style/css";
import "antd/lib/avatar/style/css";
import "antd/lib/steps/style/css";
import "antd/lib/modal/style/css";
import "react-toastify/dist/ReactToastify.css";
import ForgetPassword from "pages/user/ForgetPassword";
import { useDispatch, useSelector } from "react-redux";
import api from "api/axios";
import { getToken } from "api/api-list";
import { updateToken, userLogout } from "redux/actions/auth";
import { APIError } from "helpers/notify";
import { getCookie } from "helpers/cookies";
import ResetPassword from "pages/user/ResetPassword";
import CookiesAccept from "./components/layout/CookiesAccept";
import ConfirmProfile from "./pages/user/ConfirmProfile";
import NoMoreMembers from "pages/user/NoMoreMembers";

function App({ location: { pathname } }) {
  const { isLogin, user } = useSelector(
    ({ auth: { isLogin }, profile: { user } }) => ({
      isLogin,
      user,
    })
  );
  const dispatch = useDispatch();
  api.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/api/auth/token" && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const refresh_token = getCookie("refresh_token");
            if (!refresh_token) {
              dispatch(userLogout());
              return Promise.reject("Login again");
            }
            const res = await getToken({ refresh: refresh_token });
            await dispatch(updateToken(res.data));
            if (originalConfig.headers.Authorization)
              originalConfig.headers.Authorization = `Bearer ${res.data.access}`;
            return api(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        } else if (!originalConfig._retry && err.response.status === 401) {
          originalConfig._retry = true;
          APIError(err);
        }
      }
      return Promise.reject(err);
    }
  );

  const handleLogout = () => {
    dispatch(userLogout());
  };
  let userName = "";
  if (localStorage["first_name"])
    userName = localStorage["first_name"][0] + localStorage["last_name"][0];
  else if (user) userName = user?.first_name[0] + user?.second_name[0];
  return (
    <div className="App">
      <Fragment>
        <ToastContainer position="top-center" />
        <Header
          logout={handleLogout}
          name={userName}
          isLogin={isLogin || localStorage["isLogin"]}
          isHome={pathname === "/"}
          isLoginP={pathname === "/sign-in"}
          isSignupP={pathname === "/sign-up"}
        />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route
            exact
            path="/confirm/:uuid/:token"
            component={ConfirmProfile}
          />
          <Route exact path="/cookies-policy" component={Cookies} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route
            exact
            path="/reset-password/:id/:token"
            component={ResetPassword}
          />
          <Route
            exact
            path="/profile"
            render={(props) => (
              <Profile
                {...props}
                isLogin={isLogin || localStorage["isLogin"]}
              />
            )}
          />
          <Route
            exact
            path="/sign-in"
            render={(props) => (
              <SignIn {...props} isLogin={isLogin || localStorage["isLogin"]} />
            )}
          />
          <Route
            exact
            path="/sign-up"
            render={(props) => (
              <SignUp {...props} isLogin={isLogin || localStorage["isLogin"]} />
            )}
          />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="/sowwy" component={NoMoreMembers} />
          <Route path="/" component={NotFound} />
        </Switch>
        <Footer />
      </Fragment>
      <CookiesAccept />
    </div>
  );
}

export default withRouter(App);
