import { CustomBackground } from "components";
import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

export class TermsAndConditions extends Component {
  render() {
    const nvp_email = "info@networkvisionpartners.com";
    return (
      <section className="section section-shaped section-lg">
        <CustomBackground />
        <Container className="pt-lg-7">
          <Row>
            <Col lg="5">
              <h6 className="display-4 text-bold text-white">
                Terms & Conditions of use
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Introduction</h5>
              <p className="text-white">
                These terms and conditions apply between you, the User of this
                Website (including any sub-domains, unless expressly excluded by
                their own terms and conditions), and Vision Partners Ltd, the
                owner and operator of this Website. Please read these terms and
                conditions carefully, as they affect your legal rights. Your
                agreement to comply with and be bound by these terms and
                conditions is deemed to occur upon your first use of the
                Website. If you do not agree to be bound by these terms and
                conditions, you should stop using the Website immediately. In
                these terms and conditions, User or Users means any third party
                that accesses the Website and is not either (i) employed by
                Vision Partners Ltd and acting in the course of their employment
                or (ii) engaged as a consultant or otherwise providing services
                to Vision Partners Ltd and accessing the Website in connection
                with the provision of such services.
              </p>
              <p className="text-white mt-4">
                You must be at least 18 years of age to use this Website. By
                using the Website and agreeing to these terms and conditions,
                you represent and warrant that you are at least 18 years of age.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">
                Intellectual property and acceptable use
              </h5>
              <ol className="text-white">
                <li className="mb-2">
                  All Content included on the Website, unless uploaded by Users,
                  is the property of Vision Partners Ltd, our affiliates or
                  other relevant third parties. In these terms and conditions,
                  Content means any text, graphics, images, audio, video,
                  software, data compilations, page layout, underlying code and
                  software and any other form of information capable of being
                  stored in a computer that appears on or forms part of this
                  Website, including any such content uploaded by Users. By
                  continuing to use the Website you acknowledge that such
                  Content is protected by copyright, trademarks, database rights
                  and other intellectual property rights. Nothing on this site
                  shall be construed as granting, by implication, estoppel, or
                  otherwise, any license or right to use any trademark, logo or
                  service mark displayed on the site without the owner's prior
                  written permission
                </li>
                <li className="mb-2">
                  You may, for your own personal, non-commercial use only, do
                  the following:
                  <ul>
                    <li>
                      retrieve, display and view the Content on a computer
                      screen
                    </li>
                  </ul>
                </li>
                <li className="mb-2">
                  You must not otherwise reproduce, modify, copy, distribute or
                  use for commercial purposes any Content without the written
                  permission of Vision Partners Ltd.
                </li>
                <li className="mb-2">
                  You acknowledge that you are responsible for any Content you
                  may submit via the Website, including the legality,
                  reliability, appropriateness, originality and copyright of any
                  such Content. You may not upload to, distribute or otherwise
                  publish through the Website any Content that (i) is
                  confidential, proprietary, false, fraudulent, libellous,
                  defamatory, obscene, threatening, invasive of privacy or
                  publicity rights, infringing on intellectual property rights,
                  abusive, illegal or otherwise objectionable; (ii) may
                  constitute or encourage a criminal offence, violate the rights
                  of any party or otherwise give rise to liability or violate
                  any law; or (iii) may contain software viruses, political
                  campaigning, chain letters, mass mailings, or any form of
                  "spam." You may not use a false email address or other
                  identifying information, impersonate any person or entity or
                  otherwise mislead as to the origin of any content. You may not
                  upload commercial content onto the Website.
                </li>
                <li className="mb-2">
                  You represent and warrant that you own or otherwise control
                  all the rights to the Content you post; that the Content is
                  accurate; that use of the Content you supply does not violate
                  any provision of these terms and conditions and will not cause
                  injury to any person; and that you will indemnify Vision
                  Partners Ltd for all claims resulting from Content you supply.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Prohibited use</h5>
              <ol className="text-white" start="6">
                <li className="mb-2">
                  You may not use the Website for any of the following purposes:
                  <ul>
                    <li>
                      in any way which causes, or may cause, damage to the
                      Website or interferes with any other person's use or
                      enjoyment of the Website;
                    </li>
                    <li>
                      in any way which is harmful, unlawful, illegal, abusive,
                      harassing, threatening or otherwise objectionable or in
                      breach of any applicable law, regulation, governmental
                      order;
                    </li>
                    <li>
                      making, transmitting or storing electronic copies of
                      Content protected by copyright without the permission of
                      the owner.
                    </li>
                  </ul>
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Registration</h5>
              <ol className="text-white" start="7">
                <li className="mb-2">
                  You must ensure that the details provided by you on
                  registration or at any time are correct and complete.
                </li>
                <li className="mb-2">
                  You must inform us immediately of any changes to the
                  information that you provide when registering by updating your
                  personal details to ensure we can communicate with you
                  effectively.
                </li>
                <li className="mb-2">
                  We may suspend or cancel your registration with immediate
                  effect for any reasonable purposes or if you breach these
                  terms and conditions.
                </li>
                <li className="mb-2">
                  You may cancel your registration at any time by informing us
                  in writing to the address at the end of these terms and
                  conditions. If you do so, you must immediately stop using the
                  Website. Cancellation or suspension of your registration does
                  not affect any statutory rights.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Password and security</h5>
              <ol className="text-white" start="11">
                <li className="mb-2">
                  When you register on this Website, you will be asked to create
                  a password, which you should keep confidential and not
                  disclose or share with anyone. ​
                </li>
                <li className="mb-2">
                  If we have reason to believe that there is or is likely to be
                  any misuse of the Website or breach of security, we may
                  require you to change your password or suspend your account.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Links to other websites</h5>
              <ol className="text-white" start="13">
                <li className="mb-2">
                  This Website may contain links to other sites. Unless
                  expressly stated, these sites are not under the control of
                  Vision Partners Ltd or that of our affiliates.
                </li>
                <li className="mb-2">
                  We assume no responsibility for the content of such Websites
                  and disclaim liability for any and all forms of loss or damage
                  arising out of the use of them.
                </li>
                <li className="mb-2">
                  The inclusion of a link to another site on this Website does
                  not imply any endorsement of the sites themselves or of those
                  in control of them. ​
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Privacy Policy and Cookies Policy</h5>
              <ol className="text-white" start="16">
                <li className="mb-2">
                  Use of the Website is also governed by our Privacy Policy and
                  Cookies Policy, which are incorporated into these terms and
                  conditions by this reference. To view the Privacy Policy and
                  Cookies Policy, please click on the following:{" "}
                  <a href="/cookies-policy" target="_blank">
                    Cookies Policy
                  </a>{" "}
                  and/or{" "}
                  <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                  .
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">
                Availability of the Website and disclaimers
              </h5>
              <ol className="text-white" start="17">
                <li className="mb-2">
                  Any online facilities, tools, services or information that
                  Vision Partners Ltd makes available through the Website (the
                  Service) is provided "as is" and on an "as available" basis.
                  We give no warranty that the Service will be free of defects
                  and/or faults. To the maximum extent permitted by the law, we
                  provide no warranties (express or implied) of fitness for a
                  particular purpose, accuracy of information, compatibility and
                  satisfactory quality. Vision Partners Ltd is under no
                  obligation to update information on the Website.
                </li>
                <li className="mb-2">
                  Whilst Vision Partners Ltd uses reasonable endeavours to
                  ensure that the Website is secure and free of errors, viruses
                  and other malware, we give no warranty or guaranty in that
                  regard and all Users take responsibility for their own
                  security, that of their personal details and their computers.
                  ​
                </li>
                <li className="mb-2">
                  Vision Partners Ltd accepts no liability for any disruption or
                  non-availability of the Website.
                </li>
                <li className="mb-2">
                  Vision Partners Ltd reserves the right to alter, suspend or
                  discontinue any part (or the whole of) the Website including,
                  but not limited to, any products and/or services available.
                  These terms and conditions shall continue to apply to any
                  modified version of the Website unless it is expressly stated
                  otherwise. ​
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Limitation of liability</h5>
              <ol className="text-white" start="21">
                <li className="mb-2">
                  Nothing in these terms and conditions will: (a) limit or
                  exclude our or your liability for death or personal injury
                  resulting from our or your negligence, as applicable; (b)
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation; or (c) limit or exclude any of our or your
                  liabilities in any way that is not permitted under applicable
                  law.
                </li>
                <li className="mb-2">
                  We will not be liable to you in respect of any losses arising
                  out of events beyond our reasonable control.
                </li>
                <li className="mb-2">
                  To the maximum extent permitted by law, Vision Partners Ltd
                  accepts no liability for any of the following:
                  <ul>
                    <li>
                      any business losses, such as loss of profits, income,
                      revenue, anticipated savings, business, contracts,
                      goodwill or commercial opportunities;
                    </li>
                    <li>
                      loss or corruption of any data, database or software;
                    </li>
                    <li>
                      any special, indirect or consequential loss or damage.
                    </li>
                  </ul>
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">General</h5>
              <ol className="text-white" start="24">
                <li className="mb-2">
                  You may not transfer any of your rights under these terms and
                  conditions to any other person. We may transfer our rights
                  under these terms and conditions where we reasonably believe
                  your rights will not be affected.
                </li>
                <li className="mb-2">
                  These terms and conditions may be varied by us from time to
                  time. Such revised terms will apply to the Website from the
                  date of publication. Users should check the terms and
                  conditions regularly to ensure familiarity with the then
                  current version.
                </li>
                <li className="mb-2">
                  These terms and conditions together with the Privacy Policy
                  and Cookies Policy contain the whole agreement between the
                  parties relating to its subject matter and supersede all prior
                  discussions, arrangements or agreements that might have taken
                  place in relation to the terms and conditions.
                </li>
                <li className="mb-2">
                  The Contracts (Rights of Third Parties) Act 1999 shall not
                  apply to these terms and conditions and no third party will
                  have any right to enforce or rely on any provision of these
                  terms and conditions.
                </li>
                <li className="mb-2">
                  If any court or competent authority finds that any provision
                  of these terms and conditions (or part of any provision) is
                  invalid, illegal or unenforceable, that provision or
                  part-provision will, to the extent required, be deemed to be
                  deleted, and the validity and enforceability of the other
                  provisions of these terms and conditions will not be affected.
                </li>
                <li className="mb-2">
                  Unless otherwise agreed, no delay, act or omission by a party
                  in exercising any right or remedy will be deemed a waiver of
                  that, or any other, right or remedy. ​
                </li>
                <li className="mb-2">
                  This Agreement shall be governed by and interpreted according
                  to the law of England and Wales and all disputes arising under
                  the Agreement (including non-contractual disputes or claims)
                  shall be subject to the exclusive jurisdiction of the English
                  and Welsh courts.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white"> Vision Partners Ltd details</h5>
              <ol className="text-white" start="31">
                <li className="mb-2">
                  Vision Partners Ltd is a company incorporated in England and
                  Wales with registered number 12972276 whose registered address
                  is 71-75 Shelton Street, London, WC2H 9JQ and it operates the
                  Website{" "}
                  <a href="www.networkvisionpartners.com">
                    www.networkvisionpartners.com
                  </a>
                  .
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-white mt-6">
                You may contact Vision Partners Ltd by email at{" "}
                <a href={`mailto:${nvp_email}`}>{nvp_email}</a>.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default TermsAndConditions;
