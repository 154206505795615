import { CustomBackground } from "components";
import NotFoundImg from "../assets/imgs/404.png";
const NotFound = () => {
  return (
    <section className="section section-shaped section-l">
      <CustomBackground />
      <div className="page-not-found">
        <img src={NotFoundImg} alt="404 page not found" />
      </div>
    </section>
  );
};

export default NotFound;
