// auth
export const AUTH_ERROR = "AUTH_ERROR";
export const SIGNIN_FAIL = "SIGNIN_FAIL";
export const SIGNIN_SUCCESS = "LOGIN_SUCCESS";
export const SIGNOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const USER_LOGIN_START = "USER_LOGIN_START";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_SIGN_UP_START = "USER_SIGN_UP_START";
export const USER_SIGN_UP_SUCCESS = "USER_SIGN_UP_SUCCESS";
export const USER_SIGN_UP_FAIL = "USER_SIGN_UP_FAIL";

export const GET_COUNTRIES_START = "GET_COUNTRIES_START";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const FORGET_PASSWORD_START = "FORGET_PASSWORD_START";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const USER_LOGOUT = "USER_LOGOUT";

export const GET_PROFILE_START = "GET_PROFILE_START";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const GET_PROFILE_CONFIRM_START = "GET_PROFILE_CONFIRM_START";
export const GET_PROFILE_CONFIRM_SUCCESS = "GET_PROFILE_CONFIRM_SUCCESS";
export const GET_PROFILE_CONFIRM_FAIL = "GET_PROFILE_CONFIRM_FAIL";
export const PUT_PROFILE_CONFIRM_START = "PUT_PROFILE_CONFIRM_START";
export const PUT_PROFILE_CONFIRM_SUCCESS = "PUT_PROFILE_CONFIRM_SUCCESS";
export const PUT_PROFILE_CONFIRM_FAIL = "PUT_PROFILE_CONFIRM_FAIL";
