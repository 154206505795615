import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, CardBody, Input, InputGroup } from "reactstrap";
import { Form } from "antd";
import Spinner from "helpers/spin";
import { changePassword } from "redux/actions/auth";
const ChangePassword = ({ onCancel }) => {
  const [form] = Form.useForm();
  const { isLoading, error } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const onFinish = async (data) => {
    await dispatch(changePassword({ ...data }));
    if (!error) onCancel();
  };
  return (
    <Card className="bg-secondary shadow border-0">
      <CardBody className="px-lg-5 py-lg-5">
        <div className="text-left text-muted mb-4">
          <h3>Update Password</h3>
        </div>
        <Form onFinish={onFinish} form={form} role="form" autoComplete="off">
          <h6 className="required-field">New password</h6>
          <Form.Item
            rules={[{ required: true, message: "Field is required" }]}
            name="new_password1"
            className="mb-3"
          >
            <InputGroup className="input-group-alternative">
              <Input placeholder="New password" type="password" />
            </InputGroup>
          </Form.Item>
          <h6 className="required-field">Confirm new password</h6>
          <Form.Item
            dependencies={["new_password1"]}
            rules={[
              { required: true, message: "Field is required" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password1") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
            name="new_password2"
            className="mb-3"
          >
            <InputGroup className="input-group-alternative">
              <Input placeholder="Confirm new password" type="password" />
            </InputGroup>
          </Form.Item>
          <h6 className="required-field">Old password</h6>
          <Form.Item
            rules={[{ required: true, message: "Field is required" }]}
            name="old_password"
            className="mb-3"
          >
            <InputGroup className="input-group-alternative">
              <Input placeholder="Old password" type="password" />
            </InputGroup>
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button disabled={isLoading} className="my-4" color="success">
                Update password {isLoading && <Spinner />}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ChangePassword;
