import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
const CookiesAccept = () => {
  const [show, setShow] = useState(true);
  const handleClick = () => {
    localStorage["acceptCookies"] = true;
    setShow(false);
  };
  if (!show || localStorage["acceptCookies"]) return null;
  return (
    <div className="cookies-accept">
      We use only essential cookies to give you the best possible experience.
      <Link to="/cookies-policy" target="_blank" className="ml-1">
        {" "}
        Learn more
      </Link>
      <Button
        className="btn-icon mb-sm-0 ml-2"
        color="primary"
        size="sm"
        onClick={handleClick}
      >
        <span className="btn-inner--text">Ok</span>
      </Button>
    </div>
  );
};

export default CookiesAccept;
