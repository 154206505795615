import React from "react";
import {
  Button,
  Card,
  CardBody,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Form } from "antd";
// FIXME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { CustomBackground } from "components";
import { useDispatch } from "react-redux";
import { forgetPassword } from "redux/actions/auth";
import { useSelector } from "react-redux";
import Spinner from "../../helpers/spin";

const ForgetPassword = () => {
  // FIXME
  const [form] = Form.useForm();
  const { isLoading } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const onFinish = async (data) => {
    await dispatch(forgetPassword(data));
  };

  return (
    <section className="section section-shaped section-xl">
      <CustomBackground />
      <Container className="pt-lg-7">
        <Row className="justify-content-center">
          <Col lg="5">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h5>Forgot Password?</h5>
                  <p>Please enter your email below.</p>
                </div>
                <Form form={form} onFinish={onFinish} role="form">
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Email is required" },
                      {
                        type: "email",
                        transform: (val) => val.trim(),
                        message: "Email is not valid",
                      },
                    ]}
                    className="mb-3"
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email" />
                    </InputGroup>
                  </Form.Item>

                  <div className="text-center">
                    <Form.Item>
                      <Button
                        disabled={isLoading}
                        className="button-loading my-4"
                        color="primary"
                      >
                        Submit {isLoading && <Spinner />}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ForgetPassword;
