import {
  allCountries,
  getProfile as getProfileAPI,
  getProfileConfirm as getProfileConfirmAPI,
  updateProfile as updateProfileAPI,
  updateProfileConfirm as updateProfileConfirmAPI,
} from "api/api-list";
import { fireError } from "../../helpers/notify";

import {
  GET_COUNTRIES_START,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  GET_PROFILE_START,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PROFILE_CONFIRM_START,
  GET_PROFILE_CONFIRM_SUCCESS,
  GET_PROFILE_CONFIRM_FAIL,
  PUT_PROFILE_CONFIRM_START,
  PUT_PROFILE_CONFIRM_SUCCESS,
  PUT_PROFILE_CONFIRM_FAIL,
} from "../constants/types";

export const getCounteriesStart = () => ({
  type: GET_COUNTRIES_START,
});
export const getCounteriesSuccess = (data) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: data,
});
export const getCounteriesFailure = () => ({
  type: GET_COUNTRIES_FAIL,
});

export const getCounteries = (data) => async (dispatch) => {
  dispatch(getCounteriesStart());
  try {
    const res = await allCountries(data);
    return dispatch(getCounteriesSuccess(res.data));
  } catch (err) {
    fireError("Something went wrong please check your network!");
    return await dispatch(getCounteriesFailure());
  }
};

export const updateProfileStart = () => ({
  type: UPDATE_PROFILE_START,
});
export const updateProfileSuccess = (data) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: data,
});
export const updateProfileFailure = () => ({
  type: UPDATE_PROFILE_FAIL,
});

export const updateProfile = (data, id) => async (dispatch) => {
  dispatch(updateProfileStart());
  try {
    await updateProfileAPI(data, id);
    localStorage["first_name"] = data.first_name;
    localStorage["last_name"] = data.last_name;
    return dispatch(updateProfileSuccess(data));
  } catch (err) {
    fireError("Something went wrong please check your network!");
    return dispatch(updateProfileFailure());
  }
};

export const getProfileStart = () => ({
  type: GET_PROFILE_START,
});
export const getProfileSuccess = (data) => ({
  type: GET_PROFILE_SUCCESS,
  payload: data,
});
export const getProfileFailure = () => ({
  type: GET_PROFILE_FAIL,
});

export const getProfile = (data) => async (dispatch) => {
  dispatch(getProfileStart());
  try {
    const res = await getProfileAPI(data);
    return dispatch(getProfileSuccess(res.data));
  } catch (err) {
    fireError("Something went wrong please check your network!");
    return dispatch(getProfileFailure());
  }
};

export const getProfileConfirmStart = () => ({
  type: GET_PROFILE_CONFIRM_START,
});
export const getProfileConfirmSuccess = (data) => ({
  type: GET_PROFILE_CONFIRM_SUCCESS,
  payload: data,
});
export const getProfileConfirmFailure = () => ({
  type: GET_PROFILE_CONFIRM_FAIL,
});

export const getProfileConfirm = (data) => async (dispatch) => {
  dispatch(getProfileConfirmStart());
  try {
    const res = await getProfileConfirmAPI(data);
    return dispatch(getProfileConfirmSuccess(res.data));
  } catch (err) {
    fireError("Something went wrong please check your network!");
    return dispatch(getProfileConfirmFailure());
  }
};

export const updateProfileConfirmStart = () => ({
  type: PUT_PROFILE_CONFIRM_START,
});
export const updateProfileConfirmSuccess = (data) => ({
  type: PUT_PROFILE_CONFIRM_SUCCESS,
  payload: data,
});
export const updateProfileConfirmFailure = () => ({
  type: PUT_PROFILE_CONFIRM_FAIL,
});

export const updateProfileConfirm = (data) => async (dispatch) => {
  dispatch(updateProfileConfirmStart());
  try {
    const res = await updateProfileConfirmAPI(data);
    return dispatch(updateProfileConfirmSuccess(res.data));
  } catch (err) {
    fireError("Something went wrong please check your network!");
    return dispatch(updateProfileConfirmFailure());
  }
};
