import React, { Component } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { isMobile } from "react-device-detect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

import { CustomBackground } from "../";

import around_the_world_img from "../../assets/imgs/landing/around_the_world.svg";
// import logo_img from "../../assets/imgs/brand/logo.png";

export class Home extends Component {
  render() {
    const maxSize = 512;
    const logoStyle = {
      maxHeight: maxSize,
      maxWidth: maxSize,
    };

    return (
      <div className="position-relative">
        <section className="section section-md section-shaped pb-250" id="home">
          <CustomBackground />
          <Container className="py-lg-md d-flex">
            <div className="col px-0">
              {/* <Row className="justify-content-center mb-2">
                <div>
                  <img
                    alt=""
                    className="img-fluid"
                    src={logo_img}
                    style={logoStyle}
                  />
                </div>
              </Row> */}
              <Row className="mt-5">
                <Col className="mr-ld-6" md="6">
                  <Row className="justify-content-left mt-6">
                    <h3 className="display-3 text-left text-white">
                      Your Exclusive Network of Partners
                    </h3>
                    <p className="lead text-left text-white m-1">
                      Get ahead to reach, exceed and accelerate your goals with
                      the partners you need.
                    </p>
                  </Row>
                  <Row className="justify-content-left mt-5">
                    <div className="btn-wrapper text-center">
                      <Button
                        className="btn-icon mb-sm-0"
                        color="primary"
                        size="lg"
                        // TODO: Enable when BE is back up
                        // href="/sign-up"
                        href="/sowwy"
                      >
                        <span className="btn-inner--icon mr-1">
                          <FontAwesomeIcon icon={faUsers} />
                        </span>
                        <span className="btn-inner--text">Become a Member</span>
                      </Button>
                      {/* <Button className="btn btn-icon mb-3 mb-sm-0" color="white" size="lg" href="#">
                    <span className="btn-inner--icon mr-1">
                      <FontAwesomeIcon icon={faHandsHelping} />
                    </span>
                    <span className="btn-inner--text">Become a Client</span>
                  </Button> */}
                    </div>
                  </Row>
                </Col>
                <Col className="ml-6" sm="5">
                  <div>
                    <img
                      alt=""
                      className="img-fluid"
                      src={around_the_world_img}
                      style={!isMobile ? logoStyle : null}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          {/* <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div> */}
        </section>
      </div>
    );
  }
}

export default Home;
