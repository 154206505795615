import axios from "./axios";
import { getCookie } from "helpers/cookies";

const getHeaders = (auth) => {
  const params = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (auth) params.Authorization = `Bearer ${getCookie("access_token")}`;
  return params;
};

export const login = (data) =>
  axios({
    method: "POST",
    url: `/api/auth/token`,
    data,
    headers: { ...getHeaders() },
  });

export const signUp = (data) =>
  axios({
    method: "POST",
    url: `/api/network/prospects`,
    data,
    headers: { ...getHeaders() },
  });

export const resetPassword = (data) =>
  axios({
    method: "POST",
    url: `/api/auth/password/reset/confirm/${data.uid}/${data.token}/`,
    data,
    headers: { ...getHeaders() },
  });

export const allCountries = (data) =>
  axios({
    method: "GET",
    url: `/api/network/countries`,
    data,
    headers: { ...getHeaders() },
  });

export const updateProfile = (data, id) =>
  axios({
    method: "PATCH",
    url: `/api/network/partners/${id}`,
    data,
    headers: { ...getHeaders(true) },
  });

export const DeleteProfile = (id) =>
  axios({
    method: "DELETE",
    url: `/api/network/partners/${id}`,
    headers: { ...getHeaders(true) },
  });

export const getProfile = (id) =>
  axios({
    method: "GET",
    url: `/api/network/partners/${id}`,
    headers: { ...getHeaders(true) },
  });

export const getProfileConfirm = (data) =>
  axios({
    method: "GET",
    url: `/api/network/partners/${data.uuid}/${data.token}/confirm`,
    headers: { ...getHeaders() },
  });

export const updateProfileConfirm = (data) =>
  axios({
    method: "PUT",
    url: `/api/network/partners/${data.uuid}/${data.token}/confirm`,
    data,
    headers: { ...getHeaders() },
  });

export const changePassword = (data) =>
  axios({
    method: "POST",
    url: `/api/auth/password/change`,
    data,
    headers: { ...getHeaders(true) },
  });

export const forgetPassword = (data) =>
  axios({
    method: "POST",
    url: "/api/auth/password/reset",
    data,
    headers: { ...getHeaders() },
  });

export const getToken = (data) =>
  axios({
    method: "POST",
    url: `/api/auth/token/refresh`,
    data,
    headers: { ...getHeaders(true) },
  });
