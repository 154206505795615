import {
  login,
  signUp,
  changePassword as changePasswordAPI,
  forgetPassword as forgetPasswordAPI,
  resetPassword as resetPasswordAPI,
} from "api/api-list";
import jwtDecode from "jwt-decode";
import { APIError, fireSuccess } from "../../helpers/notify";
// import { returnErrors } from "./messages";
import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_SIGN_UP_START,
  USER_SIGN_UP_SUCCESS,
  USER_SIGN_UP_FAIL,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  FORGET_PASSWORD_START,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  UPDATE_TOKEN,
  USER_LOGOUT,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "../constants/types";

export const updateToken = (data) => {
  const expTime = new Date(data.access_token_expiration).getTime();
  return {
    type: UPDATE_TOKEN,
    payload: { ...data, exp: expTime },
  };
};

export const userLoginStart = () => ({
  type: USER_LOGIN_START,
});
export const userLoginSuccess = (data) => {
  const decoded = jwtDecode(data.access);
  const refresh = jwtDecode(data.refresh);
  const new_data = {
    ...data,
    access_token: data.access,
    refresh_token: data.refresh,
    partner_uuid: decoded.partner_uuid,
    user_id: decoded.user_id,
    exp: decoded.exp,
    expRefresh: refresh.exp,
  };
  return {
    type: USER_LOGIN_SUCCESS,
    payload: new_data,
  };
};
export const userLoginFailure = () => ({
  type: USER_LOGIN_FAIL,
});

export const userLogin = (data) => async (dispatch) => {
  dispatch(userLoginStart());
  try {
    const res = await login(data);
    return dispatch(userLoginSuccess(res.data));
  } catch (err) {
    const e = await err;
    APIError(e);
    return dispatch(userLoginFailure());
  }
};

export const userSignUpStart = () => ({
  type: USER_SIGN_UP_START,
});
export const userSignUpSuccess = (data) => ({
  type: USER_SIGN_UP_SUCCESS,
  payload: data,
});
export const userSignUpFailure = () => ({
  type: USER_SIGN_UP_FAIL,
});

export const userSignUp = (data) => async (dispatch) => {
  dispatch(userSignUpStart());
  try {
    const res = await signUp(data);
    fireSuccess("Signed up successfully.");
    return dispatch(userSignUpSuccess(res.data));
  } catch (err) {
    const e = await err;
    APIError(e);
    return dispatch(userSignUpFailure());
  }
};

export const resetPasswordStart = () => ({
  type: RESET_PASSWORD_START,
});
export const resetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data,
});
export const resetPasswordFailure = () => ({
  type: RESET_PASSWORD_FAIL,
});

export const resetPassword = (data) => async (dispatch) => {
  dispatch(resetPasswordStart());
  try {
    const res = await resetPasswordAPI(data);
    fireSuccess("Password reseted successfully.");
    return dispatch(resetPasswordSuccess(res?.data));
  } catch (err) {
    const e = await err;
    APIError(e);
    return dispatch(resetPasswordFailure());
  }
};

export const changePasswordStart = () => ({
  type: CHANGE_PASSWORD_START,
});
export const changePasswordSuccess = (data) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: data,
});
export const changePasswordFailure = () => ({
  type: CHANGE_PASSWORD_FAIL,
});

export const changePassword = (data) => async (dispatch) => {
  dispatch(changePasswordStart());
  try {
    const res = await changePasswordAPI(data);
    fireSuccess("Password changed successfully.");
    return dispatch(changePasswordSuccess(res.data));
  } catch (err) {
    return dispatch(changePasswordFailure());
  }
};

export const forgetPasswordStart = () => ({
  type: FORGET_PASSWORD_START,
});
export const forgetPasswordSuccess = (data) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: data,
});
export const forgetPasswordFailure = () => ({
  type: FORGET_PASSWORD_FAIL,
});

export const forgetPassword = (data) => async (dispatch) => {
  dispatch(forgetPasswordStart());
  try {
    const res = await forgetPasswordAPI(data);
    fireSuccess("Password reset e-mail has been sent");
    return dispatch(forgetPasswordSuccess(res.data));
  } catch (err) {
    return dispatch(forgetPasswordFailure());
  }
};

export const userLogout = () => ({
  type: USER_LOGOUT,
});
