import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Form, Checkbox } from "antd";

import { CustomBackground } from "components";
import { useDispatch } from "react-redux";
import { getProfileConfirm, updateProfileConfirm } from "redux/actions/profile";
import { useSelector } from "react-redux";
import { fireSuccess } from "helpers/notify";
// import Spinner from "../../helpers/spin";
import { Redirect } from "react-router";

const ConfirmProfile = ({ isLogin, match, history }) => {
  const [form] = Form.useForm();
  const { isLoading, error, profile } = useSelector(
    ({ auth: { isLoading, error }, profile: { profile } }) => ({
      isLoading,
      error,
      profile,
    })
  );
  const [done, setDone] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLogin) {
      const { params } = match;
      dispatch(getProfileConfirm({ uuid: params.uuid, token: params.token }));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const onFinish = async (data) => {
    setDone(false);
    const { params } = match;
    const payload = { tc: true };
    await dispatch(
      updateProfileConfirm({
        ...payload,
        uuid: params.uuid,
        token: params.token,
      })
    );
    setDone(true);
    fireSuccess("Success");
  };

  if (isLogin) return <Redirect to="/profile" />;
  if (done && !error && !isLoading) return <Redirect to="/sign-in" />;
  return (
    <section className="section section-shaped section-xl">
      <CustomBackground />
      <Container className="pt-lg-7">
        <Row className="justify-content-center">
          <Col lg="8">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h5>Please check out your profile data 👀</h5>
                  <p>
                    If your data is correct, please confirm and accept the T&Cs.
                  </p>
                  <p>
                    If your data is not correct, please contact us at{" "}
                    <a href={"mailto:info@networkvisionpartners.com"}>
                      info@networkvisionpartners.com
                    </a>
                    .
                  </p>
                </div>
                <Form form={form} onFinish={onFinish} role="form">
                  <h4 className="mb-4">Personal information</h4>
                  <h6>First Name</h6>
                  <Form.Item
                    initialValue={profile?.first_name}
                    name="first_name"
                    className="mb-3"
                  >
                    <InputGroup className="input-group-alternative">
                      <Input
                        defaultValue={profile?.first_name}
                        disabled={true}
                        placeholder="N/A"
                        type="text"
                      />
                    </InputGroup>
                  </Form.Item>
                  <h6>Last Name</h6>
                  <Form.Item
                    initialValue={profile?.last_name}
                    name="last_name"
                    className="mb-3"
                  >
                    <InputGroup className="input-group-alternative">
                      <Input
                        defaultValue={profile?.last_name}
                        disabled={true}
                        placeholder="N/A"
                        type="text"
                      />
                    </InputGroup>
                  </Form.Item>
                  <h6>Email</h6>
                  <Form.Item
                    initialValue={profile?.email}
                    name="email"
                    className="mb-3"
                  >
                    <InputGroup className="input-group-alternative">
                      <Input
                        defaultValue={profile?.email}
                        disabled={true}
                        placeholder="N/A"
                        type="email"
                      />
                    </InputGroup>
                  </Form.Item>
                  <h6>Phone Number</h6>
                  <Form.Item
                    initialValue={profile?.phone}
                    name="phone"
                    className="mb-3"
                  >
                    <InputGroup className="input-group-alternative">
                      <Input
                        defaultValue={profile?.phone}
                        disabled={true}
                        placeholder="N/A"
                        type="text"
                      />
                    </InputGroup>
                  </Form.Item>
                  <div
                    className="custom-control custom-control-alternative custom-checkbox"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Form.Item
                      rules={[
                        {
                          validator: (_, val) => {
                            return val
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("This field is required")
                                );
                          },
                        },
                      ]}
                      name="tc"
                      valuePropName="checked"
                      className="mt-3"
                    >
                      <Checkbox>
                        <span>
                          {" "}
                          I agree with the{" "}
                          <a href="/privacy-policy" target="_blank">
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a href="/terms-and-conditions" target="_blank">
                            Terms & Conditions
                          </a>
                        </span>
                      </Checkbox>
                    </Form.Item>
                  </div>
                  <Form.Item>
                    <div
                      className="mt-2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button disabled={isLoading} color="success">
                        Confirm {isLoading}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ConfirmProfile;
