import { setCookie } from "helpers/cookies";
import {
  USER_LOADED,
  USER_LOADING,
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_SIGN_UP_START,
  USER_SIGN_UP_SUCCESS,
  USER_SIGN_UP_FAIL,
  USER_LOGIN_FAIL,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  FORGET_PASSWORD_START,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  UPDATE_TOKEN,
  USER_LOGOUT,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "../constants/types";

const initialState = {
  access_token: null,
  refresh_token: null,
  isLoading: false,
  error: false,
  partner_uuid: null,
  user_id: null,
  exp: null,
  isLogin: false,
};

export default function Auth(state = initialState, action) {
  switch (action.type) {
    // handle user login
    case USER_LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case FORGET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    case FORGET_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case USER_LOGOUT:
      setCookie("access_token", null);
      setCookie("refresh_token", null);
      localStorage.clear();
      return {
        ...initialState,
      };
    case UPDATE_TOKEN:
      console.log("expTimpeStamp", action);
      setCookie("access_token", action.payload.access, action.payload.exp);
      return {
        ...state,
        exp: action.payload.exp,
        isLoading: false,
      };
    case USER_LOGIN_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case USER_LOGIN_SUCCESS:
      setCookie(
        "access_token",
        action.payload.access_token,
        action.payload.exp
      );
      setCookie(
        "refresh_token",
        action.payload.refresh_token,
        action.payload.expRefresh
      );
      localStorage["isLogin"] = true;
      localStorage["partner_uuid"] = action.payload.partner_uuid;
      return {
        ...state,
        isLoading: false,
        error: false,
        isLogin: true,
        // access_token: action.payload.access_token,
        // refresh_token: action.payload.refresh_token,
        partner_uuid: action.payload.partner_uuid,
        user_id: action.payload.user_id,
        exp: action.payload.exp,
      };
    case USER_SIGN_UP_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case USER_SIGN_UP_SUCCESS:
      setCookie("access_token", action.payload.access_token);
      setCookie("refresh_token", action.payload.refresh_token);
      return {
        ...state,
        isLoading: false,
        error: false,
        // access_token: action.payload.access_token,
        // refresh_token: action.payload.refresh_token,
        partner_uuid: action.payload.partner_uuid,
        user_id: action.payload.user_id,
        exp: action.payload.exp,
      };
    case USER_SIGN_UP_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case CHANGE_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        partner_uuid: action.payload,
      };
    // case SIGNOUT_SUCCESS:
    default:
      return state;
  }
}
