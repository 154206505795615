import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Form } from "antd";
// FIXME
import { CustomBackground } from "components";
import { useDispatch } from "react-redux";
import { resetPassword } from "redux/actions/auth";
import { useSelector } from "react-redux";
import Spinner from "../../helpers/spin";
import { Redirect } from "react-router";

const ResetPassword = ({ isLogin, match, history }) => {
  // FIXME
  const [form] = Form.useForm();
  const { isLoading, error } = useSelector(({ auth }) => auth);
  const [done, setDone] = useState(false);

  const dispatch = useDispatch();
  const onFinish = async (data) => {
    setDone(false);
    const { params } = match;
    await dispatch(
      resetPassword({ ...data, uid: params.id, token: params.token })
    );
    setDone(true);
  };
  if (isLogin) return <Redirect to="/profile" />;
  if (done && !error && !isLoading) return <Redirect to="/sign-in" />;
  return (
    <section className="section section-shaped section-xl">
      <CustomBackground />
      <Container className="pt-lg-7">
        <Row className="justify-content-center">
          <Col lg="5">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h5>Reset Password</h5>
                </div>
                <Form form={form} onFinish={onFinish} role="form">
                  <Form.Item
                    rules={[{ required: true, message: "Field is required" }]}
                    name="new_password1"
                    className="mb-3"
                  >
                    <InputGroup className="input-group-alternative">
                      <Input placeholder="New password" type="password" />
                    </InputGroup>
                  </Form.Item>
                  <Form.Item
                    dependencies={["new_password1"]}
                    rules={[
                      { required: true, message: "Field is required" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("new_password1") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                    name="new_password2"
                    className="mb-3"
                  >
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Confirm new password"
                        type="password"
                      />
                    </InputGroup>
                  </Form.Item>
                  <div className="text-center">
                    <Form.Item>
                      <Button
                        disabled={isLoading}
                        className="button-loading my-4"
                        color="primary"
                      >
                        Confirm {isLoading && <Spinner />}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a className="text-light" href="/sign-up">
                  <small>Sign Up</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ResetPassword;
