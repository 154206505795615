import { toast } from "react-toastify";

export const fireError = (msg) => {
  toast.error(msg);
};
export const fireSuccess = (msg) => {
  toast.success(msg);
};
export const APIError = (err) => {
  try {
    const errs = Object.keys(err.response.data).map((ele) => {
      return err.response.data[ele][0].length > 1
        ? err.response.data[ele][0]
        : err.response.data[ele];
    });
    errs.map((e) => fireError(e));
  } catch (erre) {
    if (err.detail) fireError(err.detail);
    else fireError("Something went wrong please try again.");
  }
};
