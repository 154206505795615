import React, { Component } from "react";
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

export class Contacts extends Component {
  render() {
    return (
      <section className="section section-lg" id="contact-us">
        <Container>
          <Row className="justify-content-center">
            <Col className="text-center">
              <div className="pr-md-5">
                <h3 className="mb-3">Contact Us</h3>
                <p className="mt-3 mb-0 font-weight-light">
                  Vision Partners Ltd.
                </p>
                <p className="mb-0 font-weight-light">
                  71-75 Shelton Street, WC2H 9JQ, London, UK
                </p>
                <p>+44 (0) 75 6838 963</p>
                <p>
                  <u>info@networkvisionpartners.com</u>
                </p>
                <Button
                  className="btn-icon-only rounded-circle mt-3"
                  color="twitter"
                  href="https://www.linkedin.com/company/network-vision-partners/"
                  id="linkedin"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </span>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target="linkedin"
                >
                  Follow us
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Contacts;
