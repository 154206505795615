import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import { CustomBackground } from "../";

import high_end_product_customization_svg from "../../assets/imgs/landing/high_end_product_customization.svg";
import identify_new_clients_svg from "../../assets/imgs/landing/identify_new_clients.svg";

export class Projects extends Component {
  render() {
    return (
      <section
        className="section section-md section-shaped pb-250"
        id="our-projects"
      >
        <CustomBackground />
        <Container>
          <Row>
            <Col className="order-md-1" md="6">
              <div className="pr-md-5">
                <h3 className="text-white">Our Projects</h3>
              </div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col className="order-md-1" md="10">
              <div className="pr-md-5">
                <p className="text-white">
                  Learn about some of our past projects below. Get in touch to
                  see what we can do for you!
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Row className="m-1">
              <Col
                className="text-white d-flex flex-column order-md-1"
                style={{ justifyContent: "center" }}
                lg="7"
              >
                <h3 className="title text-primary">Identifying New Clients</h3>
                <p>
                  We are currently supporting a reverse-factoring provider who
                  is looking to expand its client-base into Iberia. Through our
                  network of industrial manufacturers in Portugal and Spain we
                  are able to provide warm introductions and expand our client's
                  reach and sales pipeline into his new target market.
                </p>
              </Col>
              <Col className="order-md-2 ml-6" lg="4">
                <img
                  alt=""
                  className="img-fluid"
                  src={identify_new_clients_svg}
                />
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <Row className="m-1">
              <Col className="order-md-1 mr-6" lg="4">
                <img
                  alt=""
                  className="img-fluid"
                  src={high_end_product_customization_svg}
                />
              </Col>
              <Col
                className="text-white  d-flex flex-column order-md-2"
                style={{ justifyContent: "center" }}
                lg="7"
              >
                <h3 className="title text-primary">
                  High End Product Customization
                </h3>
                <p>
                  Our contact in Paris produces high end customization of
                  existing mainstream products, selling his craft at his
                  Parisian shop. Through our network of retailers we have
                  enabled our client to grow his market base and connect with
                  like-minded retailers to re-sell his products.
                </p>
              </Col>
            </Row>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Projects;
