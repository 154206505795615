import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { CustomBackground } from "components";

export class Cookies extends Component {
  render() {
    return (
      <section className="section section-shaped section-lg">
        <CustomBackground />
        <Container className="pt-lg-7">
          <Row>
            <Col lg="5">
              <h6 className="display-4 text-bold text-white">Cookies Policy</h6>
              <p className="text-white mb-6">Updated: 2021-01-18</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">What are cookies?</h5>
              <p className="text-white">
                Cookies and similar technologies are very small text documents
                or pieces of code that often contain a unique identification
                code. When you visit a website or use a mobile application, a
                computer asks your computer or mobile device for permission to
                save this file on your computer or mobile device and gain access
                to information. Information collected through cookies and
                similar technologies may include the date and time of the visit
                and how you use a particular website or mobile application.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Why do we use cookies</h5>
              <p className="text-white">
                Cookies make sure that during your visit of our online shop you
                remain logged in, all items remain stored in your shopping cart,
                you can shop safely and the website keeps running smoothly. The
                cookies also ensure that we can see how our website is used and
                how we can improve it. Furthermore, depending on your
                preferences our own cookies may be used to present you with
                targeted advertisements that match your personal interests.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">What type of cookies do we use?</h5>
              <ul className="text-white">
                <li className="mb-2">
                  Necessary cookies
                  <p>
                    These cookies are necessary for the website to function
                    properly. Some of the following actions can be performed by
                    using these cookies.
                  </p>
                  <ul className="text-white">
                    <li className="mb-2">
                      Store articles in a shopping cart for online purchases
                    </li>
                    <li className="mb-2">
                      Save your cookie preferences for this website
                    </li>
                    <li className="mb-2">Saving language preferences</li>
                    <li className="mb-2">
                      Log in to our portal. We need to check whether you are
                      logged in. ​
                    </li>
                  </ul>
                </li>
                <li className="mb-2">
                  Performance cookies
                  <p>
                    These cookies are used to gather statistical information
                    about the use of our website, also called analytics cookies.
                    We use this data for performance and website optimization.
                  </p>
                </li>
                <li className="mb-2">
                  Functional cookies
                  <p>
                    These cookies enable more functionality for our website
                    visitors. These cookies can be set by our external service
                    providers or our own website. The following functionalities
                    may or may not be activated when you accept this category.
                  </p>
                  <ul className="text-white">
                    <li className="mb-2">Live chat services</li>
                    <li className="mb-2">Watch online videos</li>
                    <li className="mb-2">Social media sharing buttons</li>
                    <li className="mb-2">
                      Login to our website with social media
                    </li>
                  </ul>
                </li>
                <li className="mb-2">
                  Advertising / tracking cookies
                  <p>
                    These cookies are set by external advertising partners and
                    are used for profiling and tracking data across multiple
                    websites. If you accept these cookies, we may show our
                    advertisements on other websites based on your user profile
                    and preferences. These cookies also save data about how many
                    visitors have seen or clicked on our advertisements in order
                    to optimize advertising campaigns.
                  </p>
                </li>
                <li className="mb-2">
                  How can I switch off or remove cookies?
                  <p>
                    You can choose to opt out of all but the necessary cookies.
                    In the settings of the browser, you can change the settings
                    to ensure that cookies will be blocked. Most browsers
                    provide you with an explanation on how to do this in the
                    so-called ‘help-function’. However, if you block the
                    cookies, it is possible that you will not be able to enjoy
                    all the technical features our website has to offer and it
                    may negatively affect your user experience.
                  </p>
                  <p className="mt-4">
                    If you want to view your consents audit please contact the
                    website administrator.
                  </p>
                </li>
                <li className="mb-2">
                  The cookies we use on our website
                  <p>
                    <i>
                      Name, Provider, Type, Category, Expires in, Description
                    </i>
                  </p>
                  <ul className="text-white">
                    <li className="mb-2">
                      XSRF- TOKEN, www.networkvisionpartners.com, First Party,
                      Necessary, Session, Used for security reasons
                    </li>
                    <li className="mb-2">
                      bSession, www.wix.com, First Party, Necessary, Permanent,
                      Used for system effectiveness measurement
                    </li>
                    <li className="mb-2">
                      hs, www.networkvisionpartners.com First Party, Necessary,
                      Session, Used for security reason
                    </li>
                    <li className="mb-2">
                      ssr-caching, www.networkvisionpartners.com, First Party,
                      Necessary, Session, Used to indicate the system from which
                      the site was rendered.
                    </li>
                    <li className="mb-2">
                      svSession, www.networkvisionpartners.com,First Party,
                      Necessary, 2 Year(s), Used in connection with user login
                    </li>
                    <li className="mb-2">
                      TS4bf5242e027, www.wix.com, First Party, Necessary,
                      Session, Used for security and anti-fraud reasons
                    </li>
                    <li className="mb-2">
                      TS8ff51b54027, www.wix.com, First Party, Necessary,
                      Session, Used for security and anti-fraud reasons
                    </li>
                    <li className="mb-2">
                      TSa459b1a6027, www.wix.com, First Party, Necessary,
                      Session, Session cookie that helps make a website usable
                      by enabling basic functions like page navigation and
                      access to secure areas of the website ​
                    </li>
                  </ul>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Cookies;
