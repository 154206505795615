import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { CustomBackground } from "../../components";

export class Privacy extends Component {
  render() {
    const nvp_email = "info@networkvisionpartners.com";
    return (
      <section className="section section-shaped section-lg">
        <CustomBackground />
        <Container className="pt-lg-7">
          <Row>
            <Col lg="5">
              <h6 className="display-4 text-bold text-white">Privacy Policy</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-white">
                This privacy policy applies between you, the user of this
                Website and Vision Partners ltd, the owner and provider of this
                Website. Vision Partners ltd takes the privacy of your
                information very seriously. This privacy policy applies to our
                use of any and all data collected by us or provided by you in
                relation to your use of the Website.
              </p>
              <p className="text-white">
                This privacy policy should be read alongside, and in addition
                to, our Terms & Conditions, which can be found{" "}
                <a href="/terms-and-conditions" target="_blank">
                  here
                </a>
                .
              </p>
              <p className="text-white">
                Please read this privacy policy carefully.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Definitions and interpretation</h5>
              <ol className="text-white">
                <li className="mb-2">
                  In this privacy policy, the following definitions are used:
                  <ol className="text-white">
                    <li className="mb-2">
                      Data: collectively all information that you submit to
                      Vision Partners ltd via the Website. This definition
                      incorporates, where applicable, the definitions provided
                      in the Data Protection Laws;
                    </li>
                    <li className="mb-2">
                      Cookies: a small text file placed on your computer by this
                      Website when you visit certain parts of the Website and/or
                      when you use certain features of the Website. Details of
                      the cookies used by this Website are set out in the clause
                      below (Cookies);
                    </li>
                    <li className="mb-2">
                      Data Protection Laws: any applicable law relating to the
                      processing of personal Data, including but not limited to
                      the Directive 96/46/EC (Data Protection Directive) or the
                      GDPR, and any national implementing laws, regulations and
                      secondary legislation, for as long as the GDPR is
                      effective in the UK;
                    </li>
                    <li className="mb-2">
                      GDPR: the General Data Protection Regulation (EU)
                      2016/679;
                    </li>
                    <li className="mb-2">
                      Vision Partners ltd, we or us: Vision Partners ltd, a
                      company incorporated in England and Wales with registered
                      number 12972276 whose registered office is at Vision
                      Partner Ltd , 71-75 Shelton Street, WC2H 9JQ;
                    </li>
                    <li className="mb-2">
                      UK and EU Cookie Law: the Privacy and Electronic
                      Communications (EC Directive) Regulations 2003 as amended
                      by the Privacy and Electronic Communications (EC
                      Directive) (Amendment) Regulations 2011;
                    </li>
                    <li className="mb-2">
                      User or you: any third party that accesses the Website and
                      is not either (i) employed by Vision Partners ltd and
                      acting in the course of their employment or (ii) engaged
                      as a consultant or otherwise providing services to Vision
                      Partners ltd and accessing the Website in connection with
                      the provision of such services;
                    </li>
                    <li className="mb-2">
                      Website: the website that you are currently using,
                      www.networkvisionpartners.com, and any sub-domains of this
                      site unless expressly excluded by their own terms and
                      conditions.
                    </li>
                  </ol>
                </li>
                <li className="mb-2">
                  In this privacy policy, unless the context requires a
                  different interpretation:
                  <ol className="text-white">
                    <li className="mb-2">
                      the singular includes the plural and vice versa;
                    </li>
                    <li className="mb-2">
                      references to sub-clauses, clauses, schedules or
                      appendices are to sub-clauses, clauses, schedules or
                      appendices of this privacy policy;
                    </li>
                    <li className="mb-2">
                      a reference to a person includes firms, companies,
                      government entities, trusts and partnerships;
                    </li>
                    <li className="mb-2">
                      "including" is understood to mean "including without
                      limitation";
                    </li>
                    <li className="mb-2">
                      reference to any statutory provision includes any
                      modification or amendment of it;
                    </li>
                    <li className="mb-2">
                      the headings and sub-headings do not form part of this
                      privacy policy.
                    </li>
                  </ol>
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Scope of this privacy policy</h5>
              <ol className="text-white" start="3">
                <li className="mb-2">
                  This privacy policy applies only to the actions of Vision
                  Partners ltd and Users with respect to this Website. It does
                  not extend to any websites that can be accessed from this
                  Website including, but not limited to, any links we may
                  provide to social media websites.
                </li>
                <li className="mb-2">
                  For purposes of the applicable Data Protection Laws, Vision
                  Partners ltd is the "data controller". This means that Vision
                  Partners ltd determines the purposes for which, and the manner
                  in which, your Data is processed. ​
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Data collected</h5>
              <ol className="text-white" start="5">
                <li className="mb-2">
                  We may collect the following Data, which includes personal
                  Data, from you:
                  <ol>
                    <li className="mb-2">name;</li>
                    <li className="mb-2">date of birth;</li>
                    <li className="mb-2">gender;</li>
                    <li className="mb-2">job title;</li>
                    <li className="mb-2">profession;</li>
                    <li className="mb-2">
                      contact Information such as email addresses and telephone
                      numbers;
                    </li>
                    <li className="mb-2">
                      demographic information such as postcode, preferences and
                      interests;
                    </li>
                    <li className="mb-2">
                      financial information such as credit / debit card numbers;
                    </li>
                    <li className="mb-2">
                      IP address (automatically collected);
                    </li>
                    <li className="mb-2">
                      web browser type and version (automatically collected);
                    </li>
                    <li className="mb-2">
                      operating system (automatically collected);
                    </li>
                    <li className="mb-2">
                      a list of URLs starting with a referring site, your
                      activity on this Website, and the site you exit to
                      (automatically collected);
                    </li>
                  </ol>
                  in each case, in accordance with this privacy policy.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">How we collect Data</h5>
              <ol className="text-white" start="6">
                <li className="mb-2">
                  We collect Data in the following ways:
                  <ol>
                    <li className="mb-2">data is given to us by you ; </li>
                    <li className="mb-2">
                      data is received from other sources;
                    </li>
                    <li className="mb-2">data is collected automatically.</li>
                  </ol>
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Data that is given to us by you</h5>
              <ol className="text-white" start="7">
                <li className="mb-2">
                  Vision Partners ltd will collect your Data in a number of
                  ways, for example:
                  <ol className="text-white">
                    <li>
                      when you contact us through the Website, by telephone,
                      post, e-mail or through any other means;
                    </li>
                    <li className="mb-2">
                      when you register with us and set up an account to receive
                      our products/services;
                    </li>
                    <li className="mb-2">
                      when you make payments to us, through this Website or
                      otherwise;
                    </li>
                    <li className="mb-2">
                      when you elect to receive marketing communications from
                      us;
                    </li>
                    <li className="mb-2">when you use our services;</li>
                  </ol>
                  in each case, in accordance with this privacy policy. Data
                  that is received from publicly available third parties sources
                </li>
                <li className="mb-2">
                  We will receive Data about you from the following publicly
                  available third party sources:
                  <ol className="text-white">
                    <li className="mb-2">linkedin.com.</li>
                  </ol>
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">
                Data that is collected automatically
              </h5>
              <ol className="text-white" start="9">
                <li className="mb-2">
                  To the extent that you access the Website, we will collect
                  your Data automatically, for example:
                  <ol className="text-white">
                    <li className="mb-2">
                      we automatically collect some information about your visit
                      to the Website. This information helps us to make
                      improvements to Website content and navigation, and
                      includes your IP address, the date, times and frequency
                      with which you access the Website and the way you use and
                      interact with its content.
                    </li>
                    <li className="mb-2">
                      we will collect your Data automatically via cookies, in
                      line with the cookie settings on your browser. For more
                      information about cookies, and how we use them on the
                      Website, see the section below, headed "Cookies".
                    </li>
                  </ol>
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Our use of Data</h5>
              <ol className="text-white" start="10">
                <li className="mb-2">
                  Any or all of the above Data may be required by us from time
                  to time in order to provide you with the best possible service
                  and experience when using our Website. Specifically, Data may
                  be used by us for the following reasons:
                  <ol className="text-white">
                    <li className="mb-2">internal record keeping;</li>
                    <li className="mb-2">
                      improvement of our products / services;
                    </li>
                    <li className="mb-2">
                      transmission by email of marketing materials that may be
                      of interest to you;
                    </li>
                  </ol>
                  in each case, in accordance with this privacy policy.
                </li>
                <li className="mb-2">
                  We may use your Data for the above purposes if we deem it
                  necessary to do so for our legitimate interests. If you are
                  not satisfied with this, you have the right to object in
                  certain circumstances (see the section headed "Your rights"
                  below).
                </li>
                <li className="mb-2">
                  For the delivery of direct marketing to you via e-mail, we'll
                  need your consent, whether via an opt-in or soft-opt-in:
                  <ol className="text-white">
                    <li className="mb-2">
                      soft opt-in consent is a specific type of consent which
                      applies when you have previously engaged with us (for
                      example, you contact us to ask us for more details about a
                      particular product/service, and we are marketing similar
                      products/services). Under "soft opt-in" consent, we will
                      take your consent as given unless you opt-out.
                    </li>
                    <li className="mb-2">
                      for other types of e-marketing, we are required to obtain
                      your explicit consent; that is, you need to take positive
                      and affirmative action when consenting by, for example,
                      checking a tick box that we'll provide.
                    </li>
                    <li className="mb-2">
                      if you are not satisfied about our approach to marketing,
                      you have the right to withdraw consent at any time. To
                      find out how to withdraw your consent, see the section
                      headed "Your rights" below.
                    </li>
                  </ol>
                </li>
                <li className="mb-2">
                  When you register with us and set up an account to receive our
                  services, the legal basis for this processing is the
                  performance of a contract between you and us and/or taking
                  steps, at your request, to enter into such a contract.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Who we share Data with</h5>
              <ol className="text-white" start="14">
                <li className="mb-2">
                  We may share your Data with the following groups of people for
                  the following reasons:
                  <ol className="text-white">
                    <li className="mb-2">
                      any of our group companies or affiliates - to ensure
                      proper administration of the website and business
                      operations;
                    </li>
                    <li className="mb-2">
                      our employees, agents and/or professional advisors - to
                      perform the service required and obtain advice from
                      professional advisers and agents;
                    </li>
                    <li className="mb-2">
                      third party service providers who provide services to us
                      which require the processing of personal data - to help
                      third party service providers in receipt of any shared
                      data to perform functions on our behalf to help ensure our
                      service operates smoothly;
                    </li>
                    <li className="mb-2">
                      third party payment providers who process payments made
                      over the Website - to enable third party payment providers
                      to process user payments and refunds;
                    </li>
                    <li className="mb-2">
                      relevant authorities - to facilitate the detection of
                      crime or the collection of taxes or duties; in each case,
                      in accordance with this privacy policy.
                    </li>
                  </ol>
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Keeping Data secure</h5>
              <ol className="text-white" start="15">
                <li className="mb-2">
                  We will use technical and organisational measures to safeguard
                  your Data, for example:
                  <ol className="text-white">
                    <li className="mb-2">
                      access to your account is controlled by a password and a
                      user name that is unique to you.
                    </li>
                    <li className="mb-2">
                      we store your Data on secure servers.
                    </li>
                    <li className="mb-2">
                      payment details are encrypted using SSL technology
                      (typically you will see a lock icon or green address bar
                      (or both) in your browser when we use this technology).
                    </li>
                  </ol>
                </li>
                <li className="mb-2">
                  Technical and organisational measures include measures to deal
                  with any suspected data breach. If you suspect any misuse or
                  loss or unauthorised access to your Data, please let us know
                  immediately by contacting us{" "}
                  <a href={`mailto:${nvp_email}`}>here</a>.
                </li>
                <li className="mb-2">
                  If you want detailed information from Get Safe Online on how
                  to protect your information and your computers and devices
                  against fraud, identity theft, viruses and many other online
                  problems, please visit www.getsafeonline.org. Get Safe Online
                  is supported by HM Government and leading businesses.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Data retention</h5>
              <ol className="text-white" start="18">
                <li className="mb-2">
                  Unless a longer retention period is required or permitted by
                  law, we will only hold your Data on our systems for the period
                  necessary to fulfil the purposes outlined in this privacy
                  policy or until you request that the Data be deleted.
                </li>
                <li className="mb-2">
                  Even if we delete your Data, it may persist on backup or
                  archival media for legal, tax or regulatory purposes.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Your Rights</h5>
              <ol className="text-white" start="20">
                <li className="mb-2">
                  You have the following rights in relation to your Data:
                  <ol className="text-white">
                    <li className="mb-2">
                      Right to access - the right to request (i) copies of the
                      information we hold about you at any time, or (ii) that we
                      modify, update or delete such information. If we provide
                      you with access to the information we hold about you, we
                      will not charge you for this, unless your request is
                      "manifestly unfounded or excessive." Where we are legally
                      permitted to do so, we may refuse your request. If we
                      refuse your request, we will tell you the reasons why.
                    </li>
                    <li className="mb-2">
                      Right to correct - the right to have your Data rectified
                      if it is inaccurate or incomplete.
                    </li>
                    <li className="mb-2">
                      Right to erase - the right to request that we delete or
                      remove your Data from our systems.
                    </li>
                    <li className="mb-2">
                      Right to restrict our use of your Data - the right to
                      "block" us from using your Data or limit the way in which
                      we can use it.
                    </li>
                    <li className="mb-2">
                      Right to data portability - the right to request that we
                      move, copy or transfer your Data.
                    </li>
                    <li className="mb-2">
                      Right to object - the right to object to our use of your
                      Data including where we use it for our legitimate
                      interests.
                    </li>
                  </ol>
                </li>
                <li className="mb-2">
                  To make enquiries, exercise any of your rights set out above,
                  or withdraw your consent to the processing of your Data (where
                  consent is our legal basis for processing your Data), please
                  contact us <a href={`mailto:${nvp_email}`}>here</a>.
                </li>
                <li className="mb-2">
                  If you are not satisfied with the way a complaint you make in
                  relation to your Data is handled by us, you may be able to
                  refer your complaint to the relevant data protection
                  authority. For the UK, this is the Information Commissioner's
                  Office (ICO). The ICO's contact details can be found on their
                  website at{" "}
                  <a
                    href="https://ico.org.uk/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    ico.org.uk
                  </a>
                  .
                </li>
                <li className="mb-2">
                  It is important that the Data we hold about you is accurate
                  and current. Please keep us informed if your Data changes
                  during the period for which we hold it.
                </li>
                <li className="mb-2">
                  Data which we collect from you may be stored and processed in
                  and transferred to countries outside of the UK and European
                  Economic Area (EEA). For example, this could occur if our
                  servers are located in a country outside the UK or EEA or one
                  of our service providers is situated in a country outside the
                  UK or EEA. We also share information with our group companies,
                  some of which are located outside the UK or EEA.
                </li>
                <li className="mb-2">
                  We will only transfer Data outside the UK or EEA where it is
                  compliant with data protection legislation and the means of
                  transfer provides adequate safeguards in relation to your
                  data, eg by way of data transfer agreement, incorporating the
                  current standard contractual clauses adopted by the European
                  Commission.
                </li>
                <li className="mb-2">
                  To ensure that your Data receives an adequate level of
                  protection, we have put in place appropriate safeguards and
                  procedures with the third parties we share your Data with.
                  This ensures your Data is treated by those third parties in a
                  way that is consistent with the Data Protection Laws.
                </li>
                <li className="mb-2">
                  This Website may, from time to time, provide links to other
                  websites. We have no control over such websites and are not
                  responsible for the content of these websites. This privacy
                  policy does not extend to your use of such websites. You are
                  advised to read the privacy policy or statement of other
                  websites prior to using them.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">
                Changes of business ownership and control
              </h5>
              <ol className="text-white" start="28">
                <li className="mb-2">
                  Vision Partners Ltd may, from time to time, expand or reduce
                  our business and this may involve the sale and/or the transfer
                  of control of all or part of Vision Partners Ltd. Data
                  provided by Users will, where it is relevant to any part of
                  our business so transferred, be transferred along with that
                  part and the new owner or newly controlling party will, under
                  the terms of this privacy policy, be permitted to use the Data
                  for the purposes for which it was originally supplied to us.
                </li>
                <li className="mb-2">
                  We may also disclose Data to a prospective purchaser of our
                  business or any part of it.
                </li>
                <li className="mb-2">
                  In the above instances, we will take steps with the aim of
                  ensuring your privacy is protected.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Cookies</h5>
              <ol className="text-white" start="31">
                <li className="mb-2">
                  This Website may place and access certain Cookies on your
                  computer. Vision Partners Ltd uses Cookies to improve your
                  experience of using the Website. Vision Partners Ltd has
                  carefully chosen these Cookies and has taken steps to ensure
                  that your privacy is protected and respected at all times.
                </li>
                <li className="mb-2">
                  All Cookies used by this Website are used in accordance with
                  current UK and EU Cookie Law.
                </li>
                <li className="mb-2">
                  Before the Website places Cookies on your computer, you will
                  be presented with a message bar requesting your consent to set
                  those Cookies. By giving your consent to the placing of
                  Cookies, you are enabling Vision Partners Ltd to provide a
                  better experience and service to you. You may, if you wish,
                  deny consent to the placing of Cookies; however certain
                  features of the Website may not function fully or as intended.
                </li>
                <li className="mb-2">
                  This Website may place the following Cookies:
                  <ul className="text-white">
                    <li>
                      Strictly necessary cookies: These are cookies that are
                      required for the operation of our website. They include,
                      for example, cookies that enable you to log into secure
                      areas of our website, use a shopping cart or make use of
                      e-billing services.
                    </li>
                    <li>
                      Analytical/performance cookies: They allow us to recognise
                      and count the number of visitors and to see how visitors
                      move around our website when they are using it. This helps
                      us to improve the way our website works, for example, by
                      ensuring that users are finding what they are looking for
                      easily.
                    </li>
                    <li>
                      Functionality cookies: These are used to recognise you
                      when you return to our website. This enables us to
                      personalise our content for you, greet you by name and
                      remember your preferences (for example, your choice of
                      language or region).
                    </li>
                  </ul>
                </li>
                <li className="mb-2">
                  You can find a list of Cookies that we use in the Cookies
                  Schedule and in our{" "}
                  <a href="/cookies-policy" target="_blank">
                    Cookies Policy
                  </a>
                  .
                </li>
                <li className="mb-2">
                  You can choose to enable or disable Cookies in your internet
                  browser. By default, most internet browsers accept Cookies but
                  this can be changed. For further details, please consult the
                  help menu in your internet browser.
                </li>
                <li className="mb-2">
                  You can choose to delete Cookies at any time; however you may
                  lose any information that enables you to access the Website
                  more quickly and efficiently including, but not limited to,
                  personalisation settings.
                </li>
                <li className="mb-2">
                  It is recommended that you ensure that your internet browser
                  is up-to-date and that you consult the help and guidance
                  provided by the developer of your internet browser if you are
                  unsure about adjusting your privacy settings.
                </li>
                <li className="mb-2">
                  For more information generally on cookies, including how to
                  disable them, please refer to aboutcookies.org. You will also
                  find details on how to delete cookies from your computer.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">General</h5>
              <ol className="text-white" start="40">
                <li className="mb-2">
                  You may not transfer any of your rights under this privacy
                  policy to any other person. We may transfer our rights under
                  this privacy policy where we reasonably believe your rights
                  will not be affected.
                </li>
                <li className="mb-2">
                  If any court or competent authority finds that any provision
                  of this privacy policy (or part of any provision) is invalid,
                  illegal or unenforceable, that provision or part-provision
                  will, to the extent required, be deemed to be deleted, and the
                  validity and enforceability of the other provisions of this
                  privacy policy will not be affected.
                </li>
                <li className="mb-2">
                  Unless otherwise agreed, no delay, act or omission by a party
                  in exercising any right or remedy will be deemed a waiver of
                  that, or any other, right or remedy.
                </li>
                <li className="mb-2">
                  This Agreement will be governed by and interpreted according
                  to the law of England and Wales. All disputes arising under
                  the Agreement will be subject to the exclusive jurisdiction of
                  the English and Welsh courts. ​
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-white">Changes to this privacy policy</h5>
              <ol className="text-white" start="44">
                <li className="mb-2">
                  Vision Partners Ltd reserves the right to change this privacy
                  policy as we may deem necessary from time to time or as may be
                  required by law. Any changes will be immediately posted on the
                  Website and you are deemed to have accepted the terms of the
                  privacy policy on your first use of the Website following the
                  alterations.
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-white mt-6">
                You may contact Vision Partners Ltd by email at{" "}
                <a href={`mailto:${nvp_email}`}>{nvp_email}</a>.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Privacy;
