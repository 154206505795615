import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/free-solid-svg-icons";
import { Select, Space, Form, Modal } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { CustomBackground } from "components";
import {
  getCounteries,
  updateProfile,
  getProfile,
} from "redux/actions/profile";
import { fireError } from "helpers/notify";
import Spinner from "helpers/spin";
import { DeleteProfile } from "api/api-list";
import { fireSuccess } from "helpers/notify";
import ChangePassword from "./ChangePassword";
import { Redirect } from "react-router";
import { capitalizeFirst } from "helpers/utils";
const { Option } = Select;
const { confirm } = Modal;

function showConfirm(handleDeactivate, text) {
  confirm({
    title: `Would you like to ${text} your account?`,
    onOk() {
      handleDeactivate();
    },
    onCancel() {
      // console.log("Cancel");
    },
  });
}

const Profile = ({ isLogin }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [form] = Form.useForm();
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [active, setActive] = useState(false);
  const { isLoading, countries, user } = useSelector(
    ({ auth: { isLoading }, profile: { countries, user } }) => ({
      isLoading,
      user,
      countries,
    })
  );

  const nvp_email = "info@networkvisionpartners.com";
  const dispatch = useDispatch();
  const onFinish = (data) => {
    if (!countriesSelected.length) {
      fireError("At least one country must be selected");
      return;
    }
    const tempData = {
      ...data,
      first_name: capitalizeFirst(data.first_name),
      last_name: capitalizeFirst(data.last_name),
      jobs: data.jobs.map((ele) => ({
        title: capitalizeFirst(ele.title),
        company: capitalizeFirst(ele.company),
      })),
    };
    dispatch(
      updateProfile({ ...tempData, countries: countriesSelected }, user.uuid)
    );
    fireSuccess("Profile updated.");
  };
  const handleChangeCountry = (value) => {
    if (value.length === 6) return;
    setCountriesSelected(value);
  };
  useEffect(() => {
    if (localStorage["partner_uuid"] && isLogin) {
      dispatch(getProfile(localStorage["partner_uuid"]));
    }
    if (!countries.length) dispatch(getCounteries());
  }, [dispatch, active]); // eslint-disable-line
  useEffect(() => {
    if (user && isLogin) {
      setCountriesSelected(user.countries);
      localStorage["name"] = user.first_name;
    }
  }, [user, isLogin]);
  const handleDeactivate = async () => {
    if (!user?.deactivated) {
      await DeleteProfile(user?.uuid);
      fireSuccess("Profile Deactivated.");
      setActive((prev) => !prev);
    } else {
      dispatch(updateProfile({ ...user, deactivated: null }, user?.uuid, true));
      fireSuccess("Profile activated.");
    }
  };
  if (!isLogin) return <Redirect to="/sign-in" />;
  if (!user || !localStorage["partner_uuid"])
    return (
      <section
        style={{ minHeight: "950px" }}
        className="section section-shaped section-lg"
      >
        <CustomBackground />
        <div
          className="loading-page"
          style={{
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="large" />
        </div>
      </section>
    );
  return (
    <section className="section section-shaped section-lg">
      <CustomBackground />
      <Container className="pt-lg-7">
        <Row className="justify-content-center">
          <Col lg="8">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-left">
                  <h4>Hello, {`${user?.first_name}`} 👋</h4>
                  {!user?.deactivated ? (
                    <p>
                      Please keep your profile up to date so that we can find
                      the best way to help you achieve your goals.
                    </p>
                  ) : (
                    <p>
                      Your profile is no longer active. You can activate it
                      again at any time!
                    </p>
                  )}
                </div>
                {!user?.deactivated ? <hr /> : null}
                {!user?.deactivated ? (
                  <Form
                    onFinish={onFinish}
                    form={form}
                    role="form"
                    autoComplete="off"
                  >
                    <h4 className="mb-4">Personal information</h4>
                    <h6>First name</h6>
                    <Form.Item
                      initialValue={user?.first_name}
                      rules={[
                        {
                          required: true,
                          message: "First name is required",
                          transform: capitalizeFirst,
                        },
                      ]}
                      name="first_name"
                      className="mb-3"
                    >
                      <InputGroup className="input-group-alternative">
                        <Input
                          defaultValue={user?.first_name}
                          placeholder="Your first name"
                          type="text"
                        />
                      </InputGroup>
                    </Form.Item>
                    <h6>Last name</h6>
                    <Form.Item
                      initialValue={user?.last_name}
                      rules={[
                        {
                          required: true,
                          message: "Last name is required",
                          transform: capitalizeFirst,
                        },
                      ]}
                      name="last_name"
                      className="mb-3"
                    >
                      <InputGroup className="input-group-alternative">
                        <Input
                          defaultValue={user?.last_name}
                          placeholder="Your last name"
                          type="text"
                        />
                      </InputGroup>
                    </Form.Item>
                    <h6>Email</h6>
                    <Form.Item
                      initialValue={user?.email}
                      rules={[{ required: true, message: "Email is required" }]}
                      name="email"
                      className="mb-3"
                    >
                      <InputGroup className="input-group-alternative">
                        <Input
                          defaultValue={user?.email}
                          placeholder="Your email"
                          type="email"
                        />
                      </InputGroup>
                    </Form.Item>
                    <h6>Phone number</h6>
                    <Form.Item
                      initialValue={user?.phone}
                      name="phone"
                      className="mb-3"
                    >
                      <InputGroup className="input-group-alternative">
                        <Input
                          defaultValue={user?.phone}
                          placeholder="Your phone number, e.g. +44..."
                          type="text"
                        />
                      </InputGroup>
                    </Form.Item>
                    <div className="mb-3">
                      <h6>Countries</h6>
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select one country"
                        value={countriesSelected}
                        onChange={handleChangeCountry}
                        optionLabelProp="label"
                      >
                        {countries.map(({ name }) => (
                          <Option key={name} value={name} label={name}>
                            <div className="demo-option-label-item">{name}</div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <h6>Jobs</h6>
                      <Form.List
                        initialValue={user ? [...user.jobs] : []}
                        name="jobs"
                      >
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              {fields.map((field) => (
                                <Space
                                  style={{ width: "100%" }}
                                  size="large"
                                  key={field.key}
                                  align="baseline"
                                >
                                  <Form.Item
                                    {...field}
                                    rules={[{ transform: capitalizeFirst }]}
                                    name={[field.name, "title"]}
                                    fieldKey={[field.fieldKey, "title"]}
                                  >
                                    <Input placeholder="Title" />
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "company"]}
                                    fieldKey={[field.fieldKey, "company"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing company",
                                        transform: capitalizeFirst,
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Company" />
                                  </Form.Item>

                                  <MinusCircleOutlined
                                    onClick={() => remove(field.name)}
                                  />
                                </Space>
                              ))}

                              <Form.Item>
                                {fields.length < 5 && (
                                  <Button
                                    type="button"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add a Job
                                  </Button>
                                )}
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.List>
                      <h6>Interest</h6>
                      <Form.Item
                        initialValue={user?.interest}
                        name="interest"
                        className="mb-3"
                      >
                        <InputGroup className="input-group-alternative">
                          <Input
                            defaultValue={user?.interest}
                            placeholder="I am looking to..."
                            type="textarea"
                          />
                        </InputGroup>
                      </Form.Item>
                    </div>
                    <Form.Item>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          disabled={isLoading}
                          className="my-4"
                          color="success"
                        >
                          Update profile {isLoading && <Spinner />}
                        </Button>
                        <p
                          style={{ cursor: "pointer" }}
                          className="my-4"
                          onClick={showModal}
                        >
                          Change password
                        </p>
                      </div>
                    </Form.Item>
                    <hr />
                    <h4 className="text-muted">Deactivate Account</h4>
                    <p className="text-muted">
                      If you deactivate your profile, you will lose access to
                      our unique network of partners and we will be unable to
                      assist you with any inquiries.
                    </p>
                    <u className="text-muted">
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          showConfirm(
                            handleDeactivate,
                            user?.deactivated ? "activate" : "deactivate"
                          )
                        }
                      >
                        {user?.deactivated ? "Activate" : "Deactivate"}
                      </p>
                    </u>
                  </Form>
                ) : (
                  <div className="text-left">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        className="mt-4 mb-4"
                        icon={faFrown}
                        size="10x"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{ cursor: "pointer" }}
                          className="my-4"
                          onClick={showModal}
                        >
                          Change password
                        </p>

                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            showConfirm(
                              handleDeactivate,
                              user?.deactivated ? "Activate" : "Deactivate"
                            )
                          }
                          className="my-4"
                        >
                          {user?.deactivated ? "Activate" : "Deactivate"}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <p className="mt-3">
                      If you wish to permanently delete your data, please
                      contact us at{" "}
                      <a href={`mailto:${nvp_email}`}>{nvp_email}</a>
                    </p>
                  </div>
                )}
              </CardBody>
            </Card>

            <Modal
              footer={null}
              onCancel={handleCancel}
              visible={isModalVisible}
            >
              <ChangePassword onCancel={handleCancel} />
            </Modal>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Profile;
