import React, { Component, Fragment } from "react";

import { Contacts, Home, Projects, Service } from "../../components";

export class Landing extends Component {
  state = {};

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <main ref="main">
        <Fragment>
          <Home />
          <Service />
          <Projects />
          <Contacts />
        </Fragment>
      </main>
    );
  }
}

export default Landing;
