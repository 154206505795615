import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { isMobile } from "react-device-detect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowRight,
  faCheck,
  faHandshake,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";

export class Service extends Component {
  render() {
    const mininnerWidth = 760;

    return (
      <section className="section section-lg" id="our-service">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="text-center">
              <div className="pr-md-5">
                <h3>Our Service</h3>
                <p>
                  Get access to our exclusive and long lasting relationship
                  based network worldwide to accelerate your goals as a business
                  or individual. Get help identifying new partners, buyers and
                  sellers around the globe through our unique contact list of
                  potential counterparts.
                </p>
                <h3 className="mt-7">How it works</h3>
                <Row className="justify-content-center mt-6">
                  <Col md="3">
                    <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                      <FontAwesomeIcon icon={faUnlock} />
                    </div>
                    <p className="mt-4">
                      Get access to our exclusive network of partners.
                    </p>
                  </Col>
                  <div className="icon icon-lg icon-shape">
                    {window.innerWidth > mininnerWidth ? (
                      <FontAwesomeIcon icon={faArrowRight} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )}
                  </div>
                  <Col md="3">
                    <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <p className="mt-4">
                      We find the perfect solution to accelerate your goals
                      through our contacts.
                    </p>
                  </Col>
                  <div className="icon icon-lg icon-shape">
                    {!isMobile ? (
                      <FontAwesomeIcon icon={faArrowRight} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )}
                  </div>
                  <Col md="3">
                    <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                      <FontAwesomeIcon icon={faHandshake} />
                    </div>
                    <p className="mt-4">
                      Get connected with the best solution.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Service;
