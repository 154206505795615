import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "reactstrap";
import { CustomBackground } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

const NoMoreMembers = () => {
  return (
    <section className="section section-shaped section-xl">
      <CustomBackground />
      <Container className="pt-lg-7">
        <Row className="justify-content-center">
          <Col lg="5">
            <div className="text-center text-muted mb-4">
              <h5 className="display-4 text-white">
                <Col>
                  <div>
                    <FontAwesomeIcon icon={faExclamationTriangle} size="4x" />
                  </div>
                  <br />
                  <div>No new members accepted</div>
                </Col>
              </h5>
              <p>
                We are sorry, but we are no longer accepting new members at this
                time.
              </p>
              <Link to="/">
                <Button color="primary" size="lg">
                  <FontAwesomeIcon icon={faArrowLeft} />
                  <span>Back</span>
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NoMoreMembers;
