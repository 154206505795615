import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  NavbarBrand,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import logo_img from "../../assets/imgs/brand/logo.png";

class Footer extends Component {
  render() {
    return (
      <footer className="footer text-left">
        <Container className="container-lg">
          <Row>
            <Col md="2">
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img alt="NVP" src={logo_img} />
              </NavbarBrand>
            </Col>
            <Col lg="3" md="6">
              <div>
                <p className="text-uppercase font-weight-bold mt-3">
                  Client & Partner
                </p>
              </div>
              <div>
                <a
                  className="font-weight-light"
                  // TODO: Enable when BE is back up
                  // href="/sign-up"
                  href="/sowwy"
                >
                  Become a Member
                </a>
              </div>
              <div>
                <a
                  className="font-weight-light"
                  // TODO: Enable when BE is back up
                  // href="/sign-in"
                  href="/sowwy"
                >
                  Member Sign In
                </a>
              </div>
              <div>
                <a
                  className="font-weight-light"
                  //TODO: Enable when BE is back up
                  // href="/forget-password"
                  href="/sowwy"
                >
                  Forgot Password?
                </a>
              </div>
            </Col>
            <Col lg="3" md="6">
              <div>
                <p className="text-uppercase font-weight-bold mt-3">Website</p>
              </div>
              <div>
                <a className="font-weight-light" href="/#home">
                  Home
                </a>
                <div>
                  <a className="font-weight-light" href="/#our-service">
                    Our Service
                  </a>
                </div>
                <div>
                  <a className="font-weight-light" href="/#our-projects">
                    Our Projects
                  </a>
                </div>
                <div>
                  <a className="font-weight-light" href="/#contact-us">
                    Contact Us
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="align-items-center justify-content-md-between">
            <Col md="6">
              <div className="copyright">
                © {new Date().getFullYear()} Vision Partners Ltd.
              </div>
            </Col>
            <Col md="6">
              <Nav className="nav-footer justify-content-end">
                <NavItem>
                  <NavLink href="/cookies-policy" target="_blank">
                    Cookies Policy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/privacy-policy" target="_blank">
                    Privacy Policy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/terms-and-conditions" target="_blank">
                    Terms & Conditions
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
