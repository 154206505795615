import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, CardBody, Input, Container, Row, Col } from "reactstrap";
import { Select, Space, Form, Checkbox, Steps } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { userSignUp } from "redux/actions/auth";
import { CustomBackground } from "components";
import { getCounteries } from "redux/actions/profile";
import Spinner from "helpers/spin";
import { Redirect } from "react-router";
import { capitalizeFirst } from "helpers/utils";
import IfComponent from "components/IfComponent";

const { Option } = Select;
const { Step } = Steps;
const steps = [
  {
    title: "Personal Info",
    content: "Personal-Info",
  },
  {
    title: "Experience",
    content: "Experience",
  },
  {
    title: "Countries",
    content: "Countries",
  },
  {
    title: "Tell us about you",
    content: "Tell-us",
  },
];

const SignUp = ({ isLogin }) => {
  const [form] = Form.useForm();
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [formData, setFormData] = useState({});
  const [done, setDone] = useState(false);
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const skip = () => {
    setCurrent(3);
  };

  const { isLoading, countries, error } = useSelector(
    ({ auth: { isLoading, error }, profile: { countries } }) => ({
      isLoading,
      error,
      countries,
    })
  );

  const dispatch = useDispatch();

  const onFinish = async (data) => {
    console.log("data", data);

    setFormData((prev) => ({ ...prev, ...data }));
    if (current !== 3) {
      next();
      return;
    }
    console.log("formData", formData);
    setDone(false);

    const tempHistory = `Referred by ${formData.history}\nInterests: ${
      formData.interests ? formData.interests : "N/A"
    }\nExperience: ${formData.experience ? formData.experience : "N/A"}`;

    const tempInterest = `${formData.interests ? formData.interests : ""}${
      formData.interests && formData.experience ? "\n" : ""
    }${formData.experience ? formData.experience : ""}`;

    const tempData = {
      ...data,
      first_name: capitalizeFirst(formData.first_name),
      last_name: capitalizeFirst(formData.last_name),
      email: formData.email.toLowerCase(),
    };

    if (formData.jobs)
      tempData.jobs = formData.jobs.map((ele) => ({
        title: capitalizeFirst(ele.title),
        company: capitalizeFirst(ele.company),
      }));

    await dispatch(
      userSignUp({
        ...tempData,
        history: tempHistory,
        interest: tempInterest,
        countries: countriesSelected,
      })
    );
    setDone(true);
  };

  const handleChangeCountry = (value) => {
    if (value.length === 6) return;
    setCountriesSelected(value);
  };

  useEffect(() => {
    dispatch(getCounteries());
  }, [dispatch]);

  if (isLogin) return <Redirect to="/profile" />;
  if (done && !error && !isLoading) return <Redirect to="/" />;
  return (
    <section className="section section-shaped section-lg">
      <CustomBackground />
      <Container className="pt-lg-7">
        <div className="text-center text-muted mb-3">
          <h5 className="display-4 text-bold text-white">Become a Member</h5>
        </div>
        <Row className="justify-content-center">
          <Col lg="9">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form form={form} onFinish={onFinish} role="form">
                  <Steps size="small" current={current}>
                    {steps.map((item) => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  </Steps>
                  <IfComponent condition={current === 0}>
                    <div className="mb-3">
                      <label className="mb-2">
                        <h6 className="lead">Personal Info</h6>
                      </label>
                      <Row>
                        <Col lg="6">
                          <h6 className="required-field">First name</h6>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "First name is required",
                              },
                            ]}
                            name="first_name"
                            className="input-group-alternative mb-3"
                          >
                            <Input
                              name="first_name"
                              placeholder="Your first name"
                              type="text"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg="6">
                          <h6 className="required-field">Last name</h6>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Last name is required",
                              },
                            ]}
                            name="last_name"
                            className="input-group-alternative mb-3"
                          >
                            <Input
                              name="last_name"
                              placeholder="Your last name"
                              type="text"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row>
                        <Col lg="6">
                          <h6 className="required-field">Email</h6>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Email is required",
                              },
                            ]}
                            name="email"
                            className="input-group-alternative mb-3"
                          >
                            <Input
                              name="email"
                              placeholder="Your email"
                              type="email"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg="6">
                          <h6>Phone number</h6>
                          <Form.Item
                            name="phone"
                            className="input-group-alternative mb-3"
                          >
                            <Input
                              name="phone"
                              placeholder="Your phone number, e.g. +44..."
                              type="text"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row>
                        <Col>
                          <h6 className="required-field">Who referred you?</h6>
                          <Form.Item
                            name="history"
                            className="input-group-alternative mb-3"
                            rules={[
                              {
                                required: true,
                                message: "Referral is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="First name & last name"
                              type="text"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </IfComponent>
                  <IfComponent condition={current === 1}>
                    <div>
                      <label className="mb-2">
                        <h6 className="lead">Experience</h6>
                      </label>
                      <Form.List
                        // initialValue={[{ company: "", title: "" }]}
                        name="jobs"
                      >
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              {fields.map((field) => (
                                <Space
                                  style={{ width: "100%" }}
                                  size="large"
                                  key={field.key}
                                  align="baseline"
                                >
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "title"]}
                                    fieldKey={[field.fieldKey, "title"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing Title",
                                      },
                                    ]}
                                  >
                                    {/* <h6 className="required-field">Title</h6> */}
                                    <Input placeholder="Title" />
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "company"]}
                                    fieldKey={[field.fieldKey, "company"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing company",
                                      },
                                    ]}
                                  >
                                    {/* <h6 className="required-field">Company</h6> */}
                                    <Input placeholder="Company" />
                                  </Form.Item>

                                  <MinusCircleOutlined
                                    onClick={() => remove(field.name)}
                                  />
                                </Space>
                              ))}

                              <Form.Item>
                                {fields.length < 5 && (
                                  <Button
                                    type="button"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add a Job
                                  </Button>
                                )}
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.List>
                    </div>
                  </IfComponent>
                  <IfComponent condition={current === 2}>
                    <div>
                      <label className="mb-2">
                        <h6 className="lead">Countries</h6>
                        {/* <p>
                          <small>
                            We should really add a description here... This is
                            not clear at all...
                          </small>
                        </p> */}
                      </label>
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select one or more countries"
                        value={countriesSelected}
                        onChange={handleChangeCountry}
                        optionLabelProp="label"
                        className="mb-4"
                      >
                        {countries.map(({ name }) => (
                          <Option key={name} value={name} label={name}>
                            <div className="demo-option-label-item">{name}</div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </IfComponent>
                  <IfComponent condition={current === 3}>
                    <div className="mb-4">
                      <label className="mb-2">
                        <h6 className="lead">Tell us about you</h6>
                        <p>
                          <small>
                            Help us contact you with the right opportunities.
                            Completing the below will give you more chances to
                            be contacted with the right opportunity.
                          </small>
                        </p>
                      </label>
                      <Form.Item
                        name="experience"
                        className="input-group-alternative mb-3"
                      >
                        <Input
                          placeholder="Tell us about your expertise..."
                          type="textarea"
                          rows="4"
                        />
                      </Form.Item>
                      <Form.Item
                        name="interests"
                        className="input-group-alternative mb-3"
                      >
                        <Input
                          placeholder="Tell us about your interests..."
                          type="textarea"
                          rows="4"
                        />
                      </Form.Item>
                    </div>
                    <hr />
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <Form.Item
                        rules={[
                          {
                            validator: (_, val) => {
                              return val
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("This field is required")
                                  );
                            },
                          },
                        ]}
                        name="tc"
                        valuePropName="checked"
                        className="mb-3"
                      >
                        <Checkbox>
                          <span>
                            {" "}
                            I agree with the{" "}
                            <a href="/privacy-policy" target="_blank">
                              Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a href="/terms-and-conditions" target="_blank">
                              Terms & Conditions
                            </a>
                          </span>
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </IfComponent>
                  {/* <label>
                    <p>
                      <small>
                        * Completing the above will give you more chances to be
                        contacted with the right opportunity.
                      </small>
                    </p>
                  </label> */}

                  <Row>
                    <Col>
                      <div className="steps-action" style={{ display: "flex" }}>
                        {current > 0 && (
                          <Button
                            color="secondary"
                            outline
                            type="button"
                            // style={{ margin: "0 8px" }}
                            onClick={() => prev()}
                          >
                            Previous
                          </Button>
                        )}
                        {current < 3 && (
                          <Button color="primary" outline type="primary">
                            Next
                          </Button>
                        )}
                        {current > 0 && current < 3 && (
                          <Button
                            color="secondary"
                            outline
                            type="button"
                            style={{ marginLeft: "auto" }}
                            onClick={() => skip()}
                          >
                            Skip
                          </Button>
                        )}
                        {current === 3 && (
                          <Button
                            disabled={isLoading}
                            color="primary"
                            style={{ marginLeft: "auto" }}
                          >
                            Sign up {isLoading && <Spinner />}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            {/* <Row className="mt-3">
              <Col className="text-right" xs="12">
                <a className="text-light" href="/sign-in">
                  <small>Sign In</small>
                </a>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignUp;
