const CustomBackground = () => (
  <div className="shape shape-style-1 shape-default bg-gradient-dark">
    <span />
    <span />
    <span />
    <span />
    <span />
    <span />
    <span />
    <span />
    <span />
  </div>
);

export default CustomBackground;
