import {
  GET_COUNTRIES_START,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  GET_PROFILE_START,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  USER_LOGOUT,
  GET_PROFILE_CONFIRM_START,
  GET_PROFILE_CONFIRM_SUCCESS,
  GET_PROFILE_CONFIRM_FAIL,
  PUT_PROFILE_CONFIRM_START,
  PUT_PROFILE_CONFIRM_SUCCESS,
  PUT_PROFILE_CONFIRM_FAIL,
} from "../constants/types";
const initialState = {
  countries: [],
  isLoading: false,
  error: false,
  user: null,
  profile: null,
};

export default function Porfile(state = initialState, action) {
  switch (action.type) {
    // handle user login
    case UPDATE_PROFILE_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        user: { ...state.user, ...action.payload },
      };
    case USER_LOGOUT:
      return {
        ...initialState,
      };
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case GET_COUNTRIES_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        countries: action.payload,
      };
    case GET_COUNTRIES_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case GET_PROFILE_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case GET_PROFILE_SUCCESS:
      localStorage["first_name"] = action.payload.first_name;
      localStorage["last_name"] = action.payload.last_name;
      return {
        ...state,
        isLoading: false,
        error: false,
        user: action.payload,
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case GET_PROFILE_CONFIRM_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case GET_PROFILE_CONFIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        profile: action.payload,
      };
    case GET_PROFILE_CONFIRM_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case PUT_PROFILE_CONFIRM_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case PUT_PROFILE_CONFIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    case PUT_PROFILE_CONFIRM_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}
